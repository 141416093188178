import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let VetyLogo = (props) => (
	<SvgIcon {...props} viewBox={"0 0 330 121"}>
		<title>Vety</title>

		<g>
			<path
				fill="#00A1F2"
				d="M19.52,72.4c-3.06,3.12-9.21,2.35-13.73-1.73c-4.52-4.07-5.7-9.91-2.64-13.03
		c3.06-3.12,9.21-2.35,13.73,1.73C21.4,63.45,22.58,69.28,19.52,72.4z"
			/>
			<path
				fill="#00A1F2"
				d="M7.56,46.99c4.4,5.44,11.31,7.31,15.42,4.17c4.11-3.14,3.88-10.1-0.53-15.55
		c-4.41-5.44-11.31-7.31-15.42-4.17C2.92,34.59,3.16,41.54,7.56,46.99z"
			/>
			<path
				fill="#00A1F2"
				d="M56.18,27.23c4.25-1.03,8.69,3.01,9.92,9.02c1.22,6.01-1.23,11.72-5.48,12.75
		c-4.26,1.03-8.69-3.01-9.92-9.02C49.47,33.97,51.93,28.26,56.18,27.23z"
			/>
			<path
				fill="#00A1F2"
				d="M31.3,16.52c4.81-1.92,10.71,1.91,13.2,8.56c2.48,6.65,0.6,13.6-4.2,15.53c-4.8,1.92-10.72-1.91-13.2-8.56
		C24.61,25.39,26.49,18.44,31.3,16.52z"
			/>
			<path
				fill="#00A1F2"
				d="M33.68,47.32c-6.09,3.38-5.94,9.48-6.64,16.03c-0.66,6.23-2.14,8.91-3.1,11.97
		c-0.86,2.75-2.86,12.17,3.9,15.47c7.96,3.89,14.24-7.08,22.03-11.41c8.42-4.67,20.51-5.02,21.31-12.01
		c0.74-6.38-4.38-10.95-11.04-12.23c-3.28-0.63-7.47-2.52-9.82-3.47C45.38,49.67,40.2,43.7,33.68,47.32z"
			/>
		</g>
		<g>
			<path
				fill="#222222"
				d="M85.11,22.39h15.79l16.91,46.14l16.58-46.14h15.57l-24.64,65.52h-15.23L85.11,22.39z"
			/>
			<path
				fill="#222222"
				d="M148.39,55.32c0-19.15,14.9-34.27,34.05-34.27c19.49,0,33.71,14.34,33.71,33.71v5.71h-53.31
		c2.13,9.29,9.41,15.46,20.16,15.46c8.51,0,15.01-4.48,18.14-11.54l12.43,7.17c-5.71,10.64-15.79,17.7-30.58,17.7
		C162.39,89.25,148.39,74.24,148.39,55.32z M163.63,47.81h37.18c-2.24-8.85-8.96-13.44-18.37-13.44
		C173.37,34.37,166.31,39.75,163.63,47.81z"
			/>
			<path
				fill="#222222"
				d="M217.72,22.39h11.2v-9.97L244.16,2v20.38h13.33v13.55h-13.33v24.53c0,11.42,1.68,13.21,13.33,13.21v14.22
		h-2.13c-20.27,0-26.43-6.5-26.43-27.33V35.94h-11.2V22.39z"
			/>
			<path
				fill="#222222"
				d="M288.5,84.66l-26.88-62.27h16.13l18.26,44.13l17.25-44.13h15.9l-39.09,95.76h-15.34L288.5,84.66z"
			/>
		</g>
	</SvgIcon>
);

VetyLogo = memo(VetyLogo);
VetyLogo.displayName = "VetyLogo";
VetyLogo.muiName = "VetyLogo";

export default VetyLogo;
